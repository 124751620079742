/* istanbul ignore file - we have a test that tests the epics in general */
import * as _ from "lodash";
import { ajax, AjaxResponse } from "rxjs/ajax";
import { Observable } from "rxjs/internal/Observable";

import { memoize, TS } from "@ctra/utils";

import { FetchChartDataPendingPayload } from "../actions";

import { buildURLWithQueryString, makeAzureApiURL, withSandboxPrefix } from "../../../utils/ajax";
import { ChartFilters } from "../typings";
import { EpicBase } from "./Base";

type DataPointsFilterType = Omit<ChartFilters, "farmIDs" | "unitSystem">;

/**
 * DataPoints Epic class
 */
class DataPointsEpic extends EpicBase {
  /**
   * Make a request URL (for exposing to the components when needed for publishing)
   * @returns {string}
   */
  @memoize
  makeRequestURL(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dependencies: any,
    payload: Omit<FetchChartDataPendingPayload, "hash" | "sourceType">
  ) {
    const {
      chartID,
      timePeriod: { startDate, endDate },
      farmIDs,
      unitSystem,
      filters = {}
    } = payload;

    const filterQuery = this.serializeFilterQuery(this.buildFiltersQuery(filters));
    const includeProjected = TS.asMoment(endDate).isAfter(TS.asMoment(TS.now()).endOf("day"));

    const body = {
      farmIds: _.join(farmIDs, ","),
      includeProjected,
      ..._.mapValues({ from: startDate, to: endDate }),
      ...(_.isEmpty(unitSystem) ? {} : { unitSystem }),
      ...(_.isEmpty(filterQuery) ? {} : { filters: filterQuery })
    };

    const baseUrl = makeAzureApiURL(
      withSandboxPrefix("analytics", dependencies.state$),
      "/compat/charts/<%= chartID %>"
    )({
      chartID
    });

    return buildURLWithQueryString(baseUrl, body);
  }

  /**
   * Make epic request
   */
  @memoize
  makeRequest(
    payload: Omit<FetchChartDataPendingPayload, "hash" | "sourceType">,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dependencies: any
  ): Observable<AjaxResponse<unknown>> {
    const {
      dataURL,
      chartID,
      timePeriod: { startDate, endDate },
      farmIDs,
      unitSystem,
      filters = {}
    } = payload;

    const { Request } = dependencies;
    const filterQuery = this.serializeFilterQuery(this.buildFiltersQuery(filters));
    const includeProjected = TS.asMoment(endDate).isAfter(TS.asMoment(TS.now()).endOf("day"));

    return dataURL
      ? ajax.get(dataURL)
      : Request.GET(
          makeAzureApiURL(
            withSandboxPrefix("analytics", dependencies.state$),
            "/compat/charts/<%= chartID %>"
          )({
            chartID
          }),
          {
            body: {
              farmIds: _.join(farmIDs, ","),
              includeProjected,
              ..._.mapValues({ from: startDate, to: endDate }),
              ...(_.isEmpty(unitSystem) ? {} : { unitSystem }),
              ...(_.isEmpty(filterQuery) ? {} : { filters: filterQuery })
            }
          }
        );
  }

  /**
   * Build the filter query to match the new filter query structure expected on the BE
   * e.g filters=herdGroupId:$in[10,100]+dimGroupKey:$in[[-30:0]]
   *
   * @note Given that this custom and doesn't follow any popular atandard,
   * a small package should be built around this on the BE and FE, because at the moment,
   * if a change is made on the expected structure on the BE without a corresponding change on the FE
   * the requests will fail.
   * @param filters
   * @private
   * @returns
   */
  private buildFiltersQuery = <T extends DataPointsFilterType>(filters: T): Record<keyof T, string> => {
    return _.reduce<T, Record<keyof T, string>>(
      //@ts-ignore
      _.omitBy(filters, _.overSome(_.isNil, _.isEmpty)),
      (acc, value, key) => {
        const formattedValue = _.isArray(value)
          ? _.join(_.map(value, (item) => JSON.stringify(item)))
          : JSON.stringify(value);

        acc[key as keyof T] = `$in[${formattedValue}]`;

        return acc;
      },
      {}
    );
  };

  private serializeFilterQuery = (filterQuery: Record<keyof DataPointsFilterType, string>): string => {
    return _.reduce(
      filterQuery,
      (acc, v, k) => {
        acc += `${acc === "" ? "" : "+"}${k}:${v}`;

        return acc;
      },
      ""
    );
  };
}

export { DataPointsEpic };
