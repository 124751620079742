/* istanbul ignore file - we have a test that tests the epics in general */
import * as _ from "lodash";
import { AjaxResponse, ajax } from "rxjs/ajax";
import { Observable } from "rxjs/internal/Observable";

import { memoize } from "@ctra/utils";

import { FetchChartDataPendingPayload } from "../actions";
import { makeAzureApiURL } from "../../../utils/ajax";

/**
 * Epic Base class - Handles Iframes
 */
class EpicBase {
  /**
   * Make a request URL (for exposing to the components when needed for publishing)
   * @returns {string}
   */
  @memoize
  makeRequestURL(dependencies: unknown, payload: Record<string, unknown>): string {
    return makeAzureApiURL("datadictionary", "/api/datadictionary/supportedcharts/rendermongochart")();
  }

  /**
   * Make epic request
   */
  @memoize
  makeRequest(
    payload: Omit<FetchChartDataPendingPayload, "hash" | "sourceType">,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dependencies: any
  ): Observable<AjaxResponse<unknown>> {
    const { dataURL, chartID, timePeriod, farmIDs, unitSystem, filters = {} } = payload;
    const { Request } = dependencies;

    return dataURL
      ? ajax.get(dataURL)
      : Request.POST(
          makeAzureApiURL("datadictionary", "/api/datadictionary/supportedcharts/rendermongochart")(),
          {
            body: {
              chartID,
              farmIDs,
              ..._.mapValues(timePeriod),
              filters: _.omitBy({ ...filters, unitSystem }, _.overSome(_.isNil, _.isEmpty))
            }
          }
        );
  }
}

export { EpicBase };
