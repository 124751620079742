import { buildRoutes, compileRoutes } from "@ctra/utils";

import {
  ChartEntity,
  ChartFilters,
  ChartTimePeriod,
  DataDescriptorEntity,
  FarmEntity,
  ISODuration,
  SavedCardState,
  V3DisplayFilter
} from "@ctra/api";

import { base, pbl } from "./base/routes";
import { auth } from "./auth/routes";
import { overview, dashboards } from "./overview/routes";
import { farms } from "./farms/routes";
import { analytics } from "./analytics/routes";
import { settings } from "./settings/routes";
import { insights } from "./insights/routes";
import { timeline } from "./events/routes";
import { farmSummaries } from "./farm-summaries/routes";
import { iofc } from "./iofc/routes";
import { network } from "./network/routes";

import { ContextType } from "@chart";
import { ChartInfo } from "@base";

export interface URIState {
  zoom?: {
    dataDescriptorID: DataDescriptorEntity["id"];
    filters?: ChartFilters;
    legendValues?: Array<string>;
    isoDuration?: ISODuration;
    timePeriod?: ChartTimePeriod;
    view?: string;
    dashboard?: string;
    displayFilters?: V3DisplayFilter[];
  };
  browseChart?: {
    openPanels?: string;
  };
  editChart?: {
    chartID: SavedCardState["id"];
    dashboard: string;
  };
  feedback?: {
    sourceType: string;
    sourceID: string;
    base64Content: string;
    context?: Record<string, unknown>;
  };
  shareChart?: {
    instance: number;
    farmID?: FarmEntity["id"];
    requestURL: string;
    base64Content?: string;
    chartID: ChartEntity["id"];
    filterContext: Pick<ContextType, "filters" | "timePeriod" | "metadata" | "isoDuration" | "series">;
    dataDescriptorID: DataDescriptorEntity["id"];
  };
  timeline?: {
    disabled?: boolean;
  };
  navigation?: {
    farmSelect: {
      overview: {
        open: boolean;
      };
      sidebar: {
        open: boolean;
      };
    };
  };
  savedChart?: SavedCardState;
  dashboard?: string;
}

/**
 * Route strings for Enterprise
 * @note the weird looking destructuring actually helps getting rid of circular imports in tests, no idea how
 */
export const Routes = buildRoutes({
  _root: "",
  auth,
  public: pbl, // published pages, but user not is logged in
  app: {
    ...base,
    overview,
    insights,
    farms,
    analytics,
    settings,
    dashboards,
    timeline,
    farmSummaries,
    iofc,
    network,
    public: pbl, // published pages, but user is logged in
    helpCenter: {
      _root: "/helpcenter"
    }
  }
});

/**
 * Compiled route strings which are callable. It comes handy during
 * navigation as you do not need to individually compile any path.
 * @example
 * ```ts
 * assert(Routes.app.farms.farm === "/app/farms/:farmID/");
 * CompiledRoutes.app.farms.farm({ farmID: 1 });
 * // "/app/farms/1/"
 * ```
 */
export const CompiledRoutes = compileRoutes(Routes);
