import * as _ from "lodash";
import { FC } from "react";
import Markdown from "react-markdown";

import {
  ActionKey,
  Analytics,
  BrowserOutlined,
  Button,
  Col,
  InfoCircleOutlined,
  Row,
  Tooltip
} from "@ctra/components";
import { GAEvent } from "@ctra/analytics";
import { Optional } from "@ctra/utils";
import { Enterprise, useTranslation } from "@ctra/i18n";

import { useFarm } from "@farms";
import { useChartAPI } from "@chart";
import { useDataDictionary, useUserPreferences } from "@base";
import { GACategories } from "@analytics";

import { MetricPicker } from "../MetricPicker";
import { Filter } from "../Filter";

import styles from "./ChartSelector.module.less";

const {
  V3: { Dropdown }
} = Analytics;

interface ChartSelectorProps {
  metric: string;
  source?: Optional<string>;
  variant: string;
  handleChange: (metric: string, source?: string, variant?: string) => void;
}

/**
 * Chart selector consisting of metric/sourc/variant combination
 * @returns
 */
export const ChartSelector: FC<ChartSelectorProps> = ({ metric, source, variant, handleChange }) => {
  const { t } = useTranslation();
  const { farm } = useFarm();

  const {
    preferences: {
      sandbox: { isEnabled: isSandboxEnabled }
    }
  } = useUserPreferences();

  const {
    dataDescriptors,
    metrics,
    groups: { families }
  } = useDataDictionary();

  const {
    chart: { title }
  } = useChartAPI();

  const {
    analytics: { config },
    chart: { description }
  } = Enterprise;

  const sources = _.get(families, metric);
  const variants = _.get(metrics, [source || metric, "variants"]);

  /**
   * Pick the variants which are supported by the current farm
   * @type {string[]}
   */
  const supportedVariants = _.filter(variants, (variant) => {
    return farm?.id ? _.includes(_.get(dataDescriptors, [variant, "supportedFarms"]), farm.id) : true;
  });

  /**
   * Ensure that metric changes use the previously selected variant type
   * if it exists within the new one
   * @param {string} metric
   */
  const trackVariant = (metric: string) => {
    const familyMetric = _.get(families, [metric, 0, "id"]);
    /**
     * Get the list of variants from family or the metric
     */
    const variants = _.get(metrics, [familyMetric || metric, "variants"]);
    /**
     * types of variants, e.g. farm/pen etc.
     */
    const variantTypes = _.map(variants, (variant) => ({
      [_.get(dataDescriptors, [variant, "dataProperties", "type"])]: variant
    }));
    /**
     * variant type of the current
     */
    const currentVariantType = _.get(dataDescriptors, [variant, "dataProperties", "type"]);
    /**
     * Get the variant from the list of variants
     */
    const currentVariant = _.filter(variantTypes, currentVariantType);

    return _.get(currentVariant, currentVariantType);
  };

  return (
    <Row className={styles.Selector} wrap={false}>
      <Col flex={1}>
        <MetricPicker
          value={metric}
          onChange={(metricID) => handleChange(metricID, void 0, trackVariant(metricID))}
        />
      </Col>
      {source && (
        <Dropdown
          size="large"
          icon={BrowserOutlined}
          placeholder={t(config.placeholders.source)}
          selection={source}
          onChange={(source) => {
            GAEvent(
              GACategories.configurator,
              "Select source",
              JSON.stringify({
                source: _.find(sources, ({ id }) => id === source)?.name
              })
            );

            handleChange(metric, source, trackVariant(source));
          }}
          values={_.map(_.orderBy(sources, "familyIndex", "asc"), ({ id, sourceTypes }) => ({
            value: id,
            label: t(config.source(_.first(sourceTypes) as string))
          }))}
        />
      )}
      <Dropdown
        size="large"
        icon={ActionKey}
        placeholder={t(config.placeholders.variant)}
        disabled={!farm}
        selection={variant}
        onChange={(variant) => {
          GAEvent(
            GACategories.configurator,
            "Select variant",
            JSON.stringify({
              variant: t(config.variant(_.get(dataDescriptors, [variant, "dataProperties", "type"])))
            })
          );

          handleChange(metric, source, variant);
        }}
        values={_.compact(
          _.map(supportedVariants, (variant) => {
            /**
             * Get the type of the variant
             * @type {DataDescriptorEntity}
             */
            const type = _.get(dataDescriptors, [variant, "dataProperties", "type"]);

            return isSandboxEnabled && type === "dimGroup"
              ? null
              : {
                  value: variant,
                  label: t(config.variant(type))
                };
          })
        )}
      />
      <Filter type={_.get(dataDescriptors, [variant, "dataProperties", "type"])} />
      {!!t<string>(description(title)) && (
        <Tooltip title={<Markdown>{t<string>(description(title))}</Markdown>}>
          <Button className={styles.Info} size="large" icon={<InfoCircleOutlined />}>
            {t(config.infoTip)}
          </Button>
        </Tooltip>
      )}
    </Row>
  );
};
