export enum GACategories {
  compareCharts = "Compare charts",
  analytics = "Analytics",
  correlations = "Correlations",
  /****** v3 *****/
  dashboards = "Dashboards",
  configurator = "Configurator",
  sharing = "Sharing"
}

export enum GAActions {
  shareChart = "Share chart",
  closeChartSharing = "Close chart sharing",
  copyToClipboard = "Copy to clipboard",
  shareViaEmail = "Share via email",
  backToDashboard = "Back to dashboard",
  selectChart = "Select chart",
  selectFarm = "Select farm",
  backTo = "Back to",
  closeSubsection = "Close subsection",
  goToAllAnalytics = "Go to all analytics",
  goToCompareCharts = "Go to compare charts",
  goToDashboard = "Go to dashboard",
  goToFarm = "Go to farm",
  openSubsection = "Open subsection",
  selectDashboard = "Select dashboard",
  addChartToList = "Add chart to list",
  switchCorrelationsTab = "Switch correlations tab",
  addCorrelationToComparison = "Add correlation to comparison",
  /****** v3 *****/
  addMetricToList = "Add metric to list",
  saveMetric = "Save metric to dashboard",
  removeMetric = "Remove metric from dashboard",
  openEvent = "Open event",
  goToChartConfig = "Go to chart configurator",
  goToAnalysisDirectly = "Go to analysis directly",
  addDashboard = "Add new dashboard",
  removeDashboard = "Remove dashboard",
  renameDashboard = "Rename dashboard",
  copyDashboard = "Copy dashboard",
  saveChanges = "Save changes"
}

export enum GALabels {
  goToFarm = "Go to farm"
}
