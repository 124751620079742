import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as _ from "lodash";
import { Formik } from "formik";
import { useDeepCompareEffect } from "use-deep-compare";
import * as Yup from "yup";

import {
  Form,
  AntDesignForm,
  AntDesignInput,
  Input,
  MailOutlined,
  LinkOutlined,
  MediaQueryDynamicImport,
  Tabs,
  Typography,
  Button,
  Space,
  Spin,
  message
} from "@ctra/components";

import { Enterprise } from "@ctra/api";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { isLocal, isProduction, Nullable } from "@ctra/utils";

import { GACategories, GAActions } from "@analytics";
import { URIState } from "@routes";
import { usePublicChart } from "@chart";

import styles from "./ShareChartDialog.module.less";

const { Paragraph } = Typography;

const {
  analytics: {
    share: { status, callback, tabs, email, link, close }
  }
} = Content;

/**
 * Dialog for sharing a chart
 * @returns {JSX.Element}
 * @constructor
 */
export const ShareChartDialog: FC = () => {
  const { t } = useTranslation();

  const {
    chart,
    attachment,
    meta: { isPublishing, isUpdating, isUpdated },
    api: { isError, publish, update }
  } = usePublicChart();

  const { state } = useLocation<URIState>();
  const dataDescriptorID = state?.shareChart?.dataDescriptorID;
  const instance = state?.shareChart?.instance;
  const isOpen = !_.isUndefined(dataDescriptorID);

  const [i, setI] = useState(instance);

  /**
   * Close the dialog
   */
  const closeDialog = () => {
    Enterprise.history.push({
      state: {}
    });
  };

  useDeepCompareEffect(() => {
    if (isOpen && dataDescriptorID && !chart) {
      publish();
    } else if (isError(chart)) {
      message.error(t<string>(callback, { action: "publish", status: "error" }));
    }
  }, [chart, isOpen, dataDescriptorID]);

  /**
   * Display a success message when the chart is updated
   */
  useEffect(() => {
    if (isUpdated && i === instance) {
      message.success(t<string>(callback, { action: "update", status: "success" }));
      closeDialog();
    }

    setI(instance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated, instance]);

  const url = isLocal()
    ? "http://localhost:3000"
    : `https://${isProduction() ? "" : "staging-"}app.connecterra.ai`;

  return isOpen ? (
    <MediaQueryDynamicImport mobile={() => import("./Mobile")} desktop={() => import("./Desktop")}>
      {(Component, { isMobile }) => (
        <Formik<{
          email: Nullable<string>;
          message: Nullable<string>;
          mode: "email" | "link";
          status: Nullable<string>;
        }>
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(t<string>(email.validation.email))
              .required(t<string>(email.validation.email))
              .nullable()
          })}
          isInitialValid={false}
          initialValues={{
            email: null,
            message: null,
            status: null,
            mode: "email"
          }}
          onSubmit={(values, formikHelpers) => {
            switch (values.mode) {
              case "email":
                if (chart && !isError(chart) && values.email) {
                  update(chart.id, values.email, _.defaultTo(values.message, void 0));
                }
                break;
            }
          }}
        >
          {({ setFieldValue, setValues, resetForm, isValid, submitForm, initialValues, values }) => (
            <Component
              centered
              visible
              onCancel={() => {
                setValues(initialValues);
                closeDialog();
              }}
              footer={_.compact([
                <Button
                  data-gtm-category={GACategories.sharing}
                  data-gtm-action={GAActions.closeChartSharing}
                  key="cancel"
                  onClick={() => {
                    resetForm();
                    closeDialog();
                  }}
                >
                  {t<string>(close)}
                </Button>,
                values.mode === "email" && chart && !isError(chart) ? (
                  <Button
                    data-gtm-category={GACategories.sharing}
                    data-gtm-action={GAActions.shareViaEmail}
                    data-gtm-label={JSON.stringify({
                      chart: chart.name,
                      email: values.email
                    })}
                    key="submit"
                    type="primary"
                    disabled={!isValid || isPublishing || !chart || isError(chart)}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    {t<string>(email.labels.submit)}
                  </Button>
                ) : null
              ])}
            >
              <Spin
                spinning={isPublishing || isUpdating}
                tip={t<string>(status, { status: isUpdating ? "updating" : "publishing" })}
              >
                <Form layout="vertical" disabled={isError(chart)}>
                  <Tabs
                    defaultActiveKey={initialValues.mode}
                    activeKey={values.mode}
                    onChange={(activeKey) => {
                      setFieldValue("mode", activeKey);
                      setFieldValue("status", null);
                    }}
                    items={[
                      {
                        key: "email",
                        label: (
                          <span>
                            <MailOutlined />
                            {t<string>(tabs.email)}
                          </span>
                        ),
                        children: (
                          <>
                            <Paragraph>{t<string>(email.description)}</Paragraph>
                            <Form.Item required name="email" label={t<string>(email.labels.email)}>
                              <Input required type="email" name="email" prefix={<MailOutlined />} />
                            </Form.Item>
                            <Form.Item name="message" label={t<string>(email.labels.message)}>
                              <Input.TextArea name="message" />
                            </Form.Item>
                          </>
                        )
                      },
                      {
                        key: "link",
                        label: (
                          <span>
                            <LinkOutlined />
                            {t<string>(tabs.link)}
                          </span>
                        ),
                        children: (
                          <>
                            <Paragraph>{t<string>(link.description)}</Paragraph>
                            <AntDesignForm.Item>
                              <Space direction="horizontal" className={styles.CopyLinkWrapper}>
                                <AntDesignInput
                                  value={
                                    chart && !isError(chart) ? `${url}/public/chart/${chart.shortId}` : void 0
                                  }
                                  readOnly
                                />
                                <Button
                                  type="primary"
                                  data-gtm-category={GACategories.sharing}
                                  data-gtm-action={GAActions.copyToClipboard}
                                  data-gtm-label={chart && !isError(chart) ? chart.name : "Sharing error"}
                                  disabled={!chart || isError(chart)}
                                  onClick={() => {
                                    if (chart && !isError(chart)) {
                                      navigator.clipboard
                                        .writeText(`${url}/public/chart/${chart.shortId}`)
                                        .then(
                                          () => {
                                            message.success(
                                              t<string>(callback, { action: "copy", status: "success" })
                                            );
                                          },
                                          () => {
                                            message.error(
                                              t<string>(callback, { action: "copy", status: "error" })
                                            );
                                          }
                                        );
                                    }
                                  }}
                                >
                                  {t<string>(link.labels.copy)}
                                </Button>
                              </Space>
                            </AntDesignForm.Item>
                          </>
                        )
                      }
                    ]}
                  />
                  {isMobile ? (
                    <Space>
                      {_.map(
                        _.compact([
                          <Button
                            key="cancel"
                            onClick={() => {
                              resetForm();
                              closeDialog();
                            }}
                          >
                            {t<string>(close)}
                          </Button>,
                          values.mode === "email" && chart && !isError(chart) ? (
                            <Button
                              key="submit"
                              type="primary"
                              data-gtm-category={GACategories.sharing}
                              data-gtm-action={GAActions.shareViaEmail}
                              data-gtm-label={JSON.stringify({
                                chart: chart.name,
                                email: values.email
                              })}
                              disabled={!isValid || isPublishing || !chart || isError(chart)}
                              onClick={() => {
                                submitForm();
                              }}
                            >
                              {t<string>(email.labels.submit)}
                            </Button>
                          ) : null
                        ])
                      )}
                    </Space>
                  ) : null}
                </Form>
              </Spin>
            </Component>
          )}
        </Formik>
      )}
    </MediaQueryDynamicImport>
  ) : null;
};
