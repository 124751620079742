import { mergeMap, map, catchError, takeUntil, filter } from "rxjs/operators";
import { ofType, Epic, StateObservable } from "redux-observable";
import { AjaxError, ajax } from "rxjs/ajax";
import { Observable, of, iif } from "rxjs";
import gzip from "gzip-js";
import _ from "lodash";

import { Action, Debug } from "@ctra/utils";

import { EnterpriseAppState } from "../../enterprise";
import { makeAzureApiURL, withSandboxPrefix } from "../../utils/ajax";

import types from "./types";
import actions, { FetchChartDataPendingPayload } from "./actions";
import { Epic as EpicFactory } from "./epic";

import genericActions from "../generic/actions";

import {
  ChartDataSource,
  CorrelationsIndexResponse,
  CorrelationsResponse,
  ShareChartResponse
} from "./typings";

/**
 * Fetch a chart data
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @returns {Observable<unknown>}
 */
const fetchChartData: Epic = (
  action$: Observable<any>,
  state$: StateObservable<any>,
  { Request }: any
): Observable<unknown> =>
  action$.pipe(
    ofType(types.FETCH_CHART_DATA.pending),
    mergeMap(
      ({
        payload: { dataURL, chartID, sourceType, timePeriod, farmIDs, unitSystem, filters, hash }
      }: {
        payload: FetchChartDataPendingPayload;
      }) => {
        const epic = EpicFactory.create(sourceType);

        const requestURL = epic.makeRequestURL(
          { Request },
          { chartID, timePeriod, farmIDs, unitSystem, filters }
        );

        return epic
          .makeRequest({ dataURL, chartID, timePeriod, farmIDs, unitSystem, filters }, { Request, state$ })
          .pipe(
            //@ts-ignore Getting a typescript warning here. Fix later
            map<{ response: ChartDataSource }, Action>(({ response }) => {
              return actions.fetchChartData.fulfill(chartID, hash, response, { requestURL });
            }),
            catchError<unknown, Observable<Action>>((error: AjaxError) => {
              const {
                auth: {
                  user,
                  token: { expires, expiresIn, refreshTokenExpires, refreshTokenExpiresIn }
                }
              } = state$.value as EnterpriseAppState;

              /**
               * Build context data to send to Sentry
               * @type {{authContext: {expiresIn: number | null | undefined, refreshTokenExpiresIn: number | null | undefined, expires: string | null | undefined, refreshTokenExpires: string | null | undefined, refresh: string | null | undefined, accessToken: string | null | undefined}, requestContext: {chartID: string, sourceType: ChartDataSourceType, timePeriod: ChartTimePeriod, farmIDs: Array<FarmEntity["id"]>, unitSystem: UnitSystemParam, filters: ChartFilters | undefined}}}
               */
              const contextData = {
                requestContext: { chartID, sourceType, timePeriod, farmIDs, unitSystem, filters },
                authContext: { user, expires, expiresIn, refreshTokenExpires, refreshTokenExpiresIn }
              };

              Debug.chartApi.error(error, { contextData });

              return of(actions.fetchChartData.reject(chartID, hash, error));
            }),
            takeUntil(
              action$.pipe(
                ofType(types.FETCH_CHART_DATA.pending, types.CANCEL_CHART_DATA_FETCH),
                filter((action) => action.payload.hash === hash)
              )
            )
          );
      }
    )
  );

/**
 * Request the user preferences for the logged-in user
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @returns {Observable<unknown>}
 */
const fetchFarmCorrelations: Epic = (
  action$: Observable<any>,
  state$: StateObservable<any>,
  { Request }: any
): Observable<unknown> =>
  action$.pipe(
    ofType(types.FETCH_FARM_CORRELATIONS.pending),
    mergeMap<ReturnType<typeof actions.fetchFarmCorrelations.start>, Observable<Promise<unknown>>>(
      ({ payload: { farmID } }) => {
        return Request.GET(
          makeAzureApiURL(withSandboxPrefix("analytics", state$), `/correlations/farm/${farmID}`, {})()
        ).pipe(
          map<{ response: CorrelationsIndexResponse }, Action>(({ response }) =>
            actions.fetchFarmCorrelations.fulfill(farmID, response)
          ),
          catchError<unknown, Observable<Action>>((error) =>
            of(actions.fetchFarmCorrelations.reject(farmID, error))
          )
        );
      }
    )
  );

/**
 * Fetch chart correlations
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const fetchChartCorrelations: Epic = (
  action$: Observable<any>,
  state$: StateObservable<any>,
  { Request }: any
): Observable<unknown> =>
  action$.pipe(
    ofType(types.FETCH_CHART_CORRELATIONS.pending),
    mergeMap<ReturnType<typeof actions.fetchChartCorrelations.start>, Observable<Promise<unknown>>>(
      ({ payload: { farmID, dataDescriptorID, excludedDataDescriptorIDList } }) => {
        return Request.GET(
          makeAzureApiURL(
            withSandboxPrefix("analytics", state$),
            `/correlations/farm/${farmID}/variants/${dataDescriptorID}`
          )(),
          excludedDataDescriptorIDList
            ? {
                body: {
                  excludedVariants: excludedDataDescriptorIDList.join(",")
                }
              }
            : void 0
        ).pipe(
          map<{ response: CorrelationsResponse }, Action>(({ response }) =>
            actions.fetchChartCorrelations.fulfill(farmID, response)
          ),
          catchError<unknown, Observable<Action>>((error) =>
            of(actions.fetchChartCorrelations.reject(farmID, error))
          )
        );
      }
    )
  );

/**
 * Convert Uint8Array to Base64 string
 * @param {Uint8Array} uint8Array
 * @returns {string}
 */
function uint8ArrayToBase64(uint8Array: Uint8Array): string {
  return btoa(String.fromCharCode(...uint8Array));
}

/**
 * Convert Base64 string back to Uint8Array
 * @param {string} base64
 * @returns {Uint8Array}
 */
function base64ToUint8Array(base64: string): Uint8Array {
  return new Uint8Array(
    atob(base64)
      .split("")
      .map((char) => char.charCodeAt(0))
  );
}

/**
 * Zip a JSON
 * @param {Record<string, unknown>} json
 * @returns {string}
 */
const zip = (json: Record<string, unknown>): string => {
  const stringified = JSON.stringify(json);
  const encoded = encodeURI(stringified);
  const compressed = gzip.zip(encoded);
  const asUint8Array = new Uint8Array(compressed);

  return uint8ArrayToBase64(asUint8Array);
};

/**
 * Unzip string to JSON
 * @param {string} str
 * @returns {Record<string, unknown>}
 */
const unzip = (str: string): any => {
  const encoded = base64ToUint8Array(str);
  const unzipped = gzip.unzip(encoded);
  const decoded = new TextDecoder("utf-8", { ignoreBOM: true }).decode(new Uint8Array(unzipped));
  const urlDecoded = decodeURI(decoded);

  return JSON.parse(urlDecoded);
};

/**
 * Share a chart
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @returns {Observable<unknown>}
 */
const shareChart: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.SHARE_CHART.pending),
    mergeMap<ReturnType<typeof actions.shareChart.start>, Observable<Promise<unknown>>>(
      ({ payload: { hash, ...rest } }) => {
        return Request.POST(makeAzureApiURL("notes", "/notes")(), {
          body: rest
        }).pipe(
          map<{ response: ShareChartResponse }, Action>(({ response }) =>
            actions.shareChart.fulfill({ hash, ...response })
          ),
          catchError<Action, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);
            const statusCode = _.get(response, ["statusCode"]);
            const details = _.get(response, ["details"]);

            return of(actions.shareChart.reject(hash, error, statusCode, details));
          })
        );
      }
    )
  );

/**
 * Update a shared a chart
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @returns {Observable<unknown>}
 */
const updateSharedChart: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.UPDATE_SHARED_CHART.pending),
    mergeMap<ReturnType<typeof actions.updateSharedChart.start>, Observable<Promise<unknown>>>(
      ({ payload: { id, hash, ...rest } }) => {
        return Request.PUT(makeAzureApiURL("notes", "/notes/<%= id %>")({ id }), {
          body: rest
        }).pipe(
          map<{ response: ShareChartResponse }, Action>(({ response }) =>
            actions.updateSharedChart.fulfill({ hash, ...response })
          ),
          catchError<Action, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);
            const statusCode = _.get(response, ["statusCode"]);
            const details = _.get(response, ["details"]);

            return of(actions.updateSharedChart.reject(hash, error, statusCode, details));
          })
        );
      }
    )
  );

/**
 * Load a shared chart
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @returns {Observable<unknown>}
 */
const fetchSharedChart: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_SHARED_CHART.pending),
    mergeMap<ReturnType<typeof actions.fetchSharedChart.start>, Observable<Promise<unknown>>>(
      ({ payload: { shortID } }) => {
        return Request.GET(makeAzureApiURL("notes", "/notes/<%= shortID %>")({ shortID })).pipe(
          mergeMap<{ response: ShareChartResponse }, Observable<any>>(
            ({ response: { attachments, ...rest } }) => {
              const stateAttachment = _.find(attachments, ["description", "state"]);

              return iif(
                () => !!stateAttachment,
                ajax.get(stateAttachment!.url).pipe(
                  mergeMap(({ response }) => {
                    return [
                      genericActions.rehydrate(response as unknown as EnterpriseAppState),
                      actions.fetchSharedChart.fulfill({
                        attachments: _.filter(attachments, (attachment) => attachment.name !== "state"),
                        ...rest
                      })
                    ];
                  })
                ),
                of(
                  actions.fetchSharedChart.fulfill({
                    attachments: _.filter(attachments, (attachment) => attachment.name !== "state"),
                    ...rest
                  })
                )
              );
            }
          ),
          catchError<Action, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);
            const statusCode = _.get(response, ["statusCode"]);
            const details = _.get(response, ["details"]);

            return of(actions.fetchSharedChart.reject(error, statusCode, details));
          })
        );
      }
    )
  );

/**
 * Fetch attachment from a shared chart
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @returns {Observable<unknown>}
 */
const fetchSnapshotData: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_SNAPSHOT_DATA.pending),
    mergeMap<ReturnType<typeof actions.fetchSnapshotData.start>, Observable<Promise<unknown>>>(
      ({ payload: { url } }) => {
        return Request.GET(url).pipe(
          map<{ response: Record<string, unknown> }, Action>(({ response }) =>
            actions.fetchSnapshotData.fulfill(response)
          ),
          catchError<Action, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);
            const statusCode = _.get(response, ["statusCode"]);
            const details = _.get(response, ["details"]);

            return of(actions.fetchSnapshotData.reject(error, statusCode, details));
          })
        );
      }
    )
  );

export default {
  fetchChartData,
  fetchFarmCorrelations,
  fetchChartCorrelations,
  shareChart,
  updateSharedChart,
  fetchSharedChart,
  fetchSnapshotData
};
