import { createAsyncActions } from "@ctra/utils";

import { FeedbackPayload } from "./typings";
import types from "./types";
import { getStorageKey } from "../../utils/versioning";
import { EnterpriseAppState } from "../../enterprise";

/**
 * Send user feedback
 */
const sendFeedback = createAsyncActions(
  types.SEND_FEEDBACK,
  (payload: FeedbackPayload) => payload,
  (response: Record<string, unknown>) => response,
  (error, statusCode, details) => ({
    error,
    statusCode,
    details
  })
);

/**
 * Rehydrate the enterprise state from any given state
 * @param {EnterpriseAppState} state
 * @returns {{payload: EnterpriseAppState, type: string, key: string}}
 */
const rehydrate = (
  state: EnterpriseAppState
): { payload: Partial<EnterpriseAppState>; type: string; key: string } => ({
  type: "persist/REHYDRATE",
  key: getStorageKey("enterprise"),
  payload: state
});

export default {
  rehydrate,
  sendFeedback
};
