import { Epic, ofType, StateObservable } from "redux-observable";
import { catchError, mergeMap, map } from "rxjs/operators";
import { Observable, of } from "rxjs";

import { Action, Debug, Nullable } from "@ctra/utils";

import { makeAzureApiURL } from "../../utils/ajax";
import types from "./types";
import actions from "./actions";
import { FarmSummarySubscriptionsResponse, FarmSummarySubscriptionsResponseItem } from "./typings";
import * as _ from "lodash";

/**
 * Create a new farm summary
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @returns {Observable<unknown>}
 */
const createFarmSummary: Epic = (
  action$: Observable<any>,
  state$: StateObservable<any>,
  { Request }: any
): Observable<unknown> =>
  action$.pipe(
    ofType(types.CREATE_FARM_SUMMARY.pending),
    mergeMap<ReturnType<typeof actions.createFarmSummary.start>, Observable<Promise<unknown>>>(
      ({ payload: { start, end, farmID } }) =>
        Request.POST(makeAzureApiURL("accounts", "/self/farm-summaries")(), {
          body: { startDate: start, endDate: end, farmId: farmID }
        }).pipe(
          map<{ response: Record<string, unknown> }, Action>(({ response }) =>
            actions.createFarmSummary.fulfill(response)
          ),
          catchError<
            { status: number; message: string; response: Nullable<{ error: string; statusCode: number }> },
            Observable<Action>
          >(({ response, message, status }) => {
            Debug.farmSummariesAPI.error("Failed to create farm summary", {
              start,
              end,
              farmID,
              response,
              message,
              status
            });

            if (_.isString(response)) {
              return of(actions.createFarmSummary.reject(message, status, ""));
            } else {
              const error = _.get(response, ["error"]);
              const statusCode = _.get(response, ["statusCode"]);
              const details = _.get(response, ["details"]);

              return of(actions.createFarmSummary.reject(error, statusCode, details));
            }
          })
        )
    )
  );

/**
 * Fetch subscriptions
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @returns {Observable<unknown>}
 */
const fetchSubscriptions: Epic = (
  action$: Observable<any>,
  state$: StateObservable<any>,
  { Request }: any
): Observable<unknown> =>
  action$.pipe(
    ofType(types.FETCH_SUBSCRIPTION_LIST.pending),
    mergeMap<ReturnType<typeof actions.fetchSubscriptionList.start>, Observable<Promise<unknown>>>(() =>
      Request.GET(makeAzureApiURL("accounts", "/self/preferences/farm-summaries")()).pipe(
        map<{ response: FarmSummarySubscriptionsResponse }, Action>(({ response }) =>
          actions.fetchSubscriptionList.fulfill(response)
        ),
        catchError<
          { status: number; message: string; response: Nullable<{ error: string; statusCode: number }> },
          Observable<Action>
        >(({ response, message, status }) => {
          Debug.farmSummariesAPI.error("Failed to fetch farm-summary subscriptions", {
            response,
            message,
            status
          });

          return of(
            actions.fetchSubscriptionList.reject(response ? response : { statusCode: status, error: message })
          );
        })
      )
    )
  );

/**
 * Update subscription
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @returns {Observable<unknown>}
 */
const updateSubscription: Epic = (
  action$: Observable<any>,
  state$: StateObservable<any>,
  { Request }: any
): Observable<unknown> =>
  action$.pipe(
    ofType(types.UPDATE_SUBSCRIPTION.pending),
    mergeMap<ReturnType<typeof actions.updateSubscription.start>, Observable<Promise<unknown>>>(
      ({ payload: { farmID, isEnabled } }) =>
        Request.PUT(makeAzureApiURL("accounts", "/self/preferences/farm-summaries")(), {
          body: { farmId: farmID, isEnabled }
        }).pipe(
          map<{ response: FarmSummarySubscriptionsResponseItem }, Action>(({ response }) =>
            actions.updateSubscription.fulfill(response)
          ),
          catchError<
            { status: number; message: string; response: Nullable<{ error: string; statusCode: number }> },
            Observable<Action>
          >(({ response, message, status }) => {
            Debug.farmSummariesAPI.error("Failed to update farm-summary subscription", {
              response,
              message,
              status
            });

            return of(
              actions.updateSubscription.reject(response ? response : { statusCode: status, error: message })
            );
          })
        )
    )
  );

export default {
  createFarmSummary,
  fetchSubscriptions,
  updateSubscription
};
