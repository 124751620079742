import { FC, createContext, useContext, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AjaxError } from "rxjs/ajax";
import * as _ from "lodash";
import moment from "moment";

import { isDispatched, isPending, isRejected, Nullable, Optional, TS } from "@ctra/utils";
import { Enterprise, Charts, EnterpriseAppState, ChartData, ChartTimePeriod } from "@ctra/api";

import { useChartAPI } from "../ChartAPIContext";
import { useChartFilters } from "../ChartFilterContext";
import { useDebounce } from "../../hooks/useDebounce";
import { useDeepCompareEffect } from "use-deep-compare";
import { makeChartHash } from "@ctra/api/src/modules/chart/utils";

type ContextType = {
  data: Optional<ChartData["data"]>;
  meta: Optional<ChartData["meta"]>;
  requestURL: Nullable<string>;
  flags: {
    isLoading: boolean;
    hasFailed: boolean;
    hasConsent: boolean;
  };
};

/**
 * Default chart data provider context
 */
const DefaultContext = createContext<ContextType>({
  data: void 0,
  meta: void 0,
  requestURL: null,
  flags: {
    isLoading: true,
    hasFailed: false,
    hasConsent: false
  }
});

/**
 * Adjust the date range to the current date if the end date is in the future
 * @param {string} startDate
 * @param {string} endDate
 * @param {Nullable<string>} maxProjection
 * @param {boolean} forceProjection
 * @returns {ChartTimePeriod}
 */
const adjustTimePeriod = (
  { startDate, endDate }: ChartTimePeriod,
  maxProjection?: Nullable<string>,
  forceProjection?: boolean
): ChartTimePeriod => {
  const endMoment = TS.asMoment(endDate);
  const now = moment().utc().endOf("day");
  const kindOfToday = now.diff(endMoment, "days") < 2;
  let adjustedEndDate = endDate;

  if (kindOfToday && forceProjection) {
    adjustedEndDate = endMoment.clone().add(moment.duration(maxProjection)).toISOString();
  }

  if (endMoment.isAfter(now) && maxProjection) {
    const maxEndDate = endMoment.clone().add(moment.duration(maxProjection));
    adjustedEndDate = (maxEndDate.isAfter(endMoment) ? endMoment : maxEndDate).toISOString();
  }

  return { startDate, endDate: adjustedEndDate };
};

/**
 * This is where the chart ID and the filters convert to a URL
 * @constructor
 */
export const _ChartDataProvider: FC = memo(({ children }) => {
  const dispatch = useDispatch();

  const {
    chart: {
      dataURL,
      id: chartID,
      dataProperties: { maxProjectionInterval }
    },
    meta: {
      unitSystem,
      sourceType,
      farms: { hasSupport: farmIDs }
    }
  } = useChartAPI();

  const {
    timePeriod,
    filters,
    metadata: {
      flags: { isBackendAggregated }
    }
  } = useChartFilters();

  const adjustedTimePeriod = adjustTimePeriod(timePeriod, maxProjectionInterval);
  const queryFilter = isBackendAggregated ? filters : undefined;

  /**
   * Make a hash from the chart fingerprint
   */
  const hash = Charts.utils.makeChartHash(chartID, adjustedTimePeriod, farmIDs, unitSystem, queryFilter);

  /**
   * Check if there is a URL in our store already
   */
  const data = useSelector<EnterpriseAppState, Optional<ChartData>>((state) =>
    _.isEmpty(adjustedTimePeriod) ? void 0 : Enterprise.entities.getChartData(state, { hash })
  );

  /**
   * Tell whether the fetching action has already been dispatched
   * @type {boolean}
   */
  const dispatched = useSelector<EnterpriseAppState, boolean>((state) =>
    isDispatched(state, Charts.types.FETCH_CHART_DATA, { primaryValue: hash })
  );

  /**
   * Tell whether the chart data is being fetched
   * @type {boolean}
   */
  const isLoading = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Charts.types.FETCH_CHART_DATA, { primaryValue: hash })
  );

  /**
   * Tell whether the chart has failed loading
   */
  const hasFailed = useSelector<EnterpriseAppState, boolean>((state) =>
    isRejected(state, Charts.types.FETCH_CHART_DATA, { primaryValue: hash })
  );

  /**
   * Get the request URL for the chart
   * @type {Nullable<string>}
   */
  const requestURL = useSelector<EnterpriseAppState, Nullable<string>>((state) =>
    Enterprise.entities.getChartRequestURL(state, { hash })
  );

  /**
   * Get ajax error if the chart has failed to load
   */
  const error = useSelector<EnterpriseAppState, AjaxError>((state) =>
    Enterprise.entities.getChartError(state, { hash })
  );

  /**
   * If the error code is 401
   * then the farm owner does not have consent
   * to view the particular chart/KPI
   */
  const hasConsent = error?.status !== 401;

  const { debounce } = useDebounce();

  useDeepCompareEffect(() => {
    /**
     * @todo allow re-dispatch when the chart has failed the user requests a reload
     */
    if (!_.isEmpty(adjustedTimePeriod) && !data && !dispatched) {
      debounce(
        () => {
          dispatch(
            Charts.actions.fetchChartData.start(
              chartID,
              sourceType,
              adjustedTimePeriod,
              farmIDs,
              unitSystem,
              queryFilter,
              dataURL
            )
          );
        },
        {
          chartID,
          sourceType,
          adjustedTimePeriod,
          farmIDs,
          unitSystem,
          queryFilter
        }
      );
    }

    // return () => {
    //   dispatch(
    //     Charts.actions.cancelChartDataFetch(
    //       makeChartHash(chartID, adjustedTimePeriod, farmIDs, unitSystem, queryFilter)
    //     )
    //   );
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatched, data, chartID, farmIDs, adjustedTimePeriod, sourceType, unitSystem, queryFilter]);

  return (
    <DefaultContext.Provider
      value={{
        flags: {
          isLoading: (!dispatched && !data) || isLoading,
          hasFailed,
          hasConsent
        },
        requestURL,
        data: _.get(data, "data"),
        meta: _.get(data, "meta")
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
});

export const ChartDataContext = {
  Provider: _ChartDataProvider,
  Consumer: DefaultContext.Consumer
};

_ChartDataProvider.displayName = "_ChartDataProvider";

/**
 * Hook for chart data provider context
 * @returns
 */
export const useChartData = (): ContextType => useContext<ContextType>(DefaultContext);
