import moment from "moment";
import * as _ from "lodash";

import { Enterprise as App, DataDescriptorEntity, ChartSeriesType } from "@ctra/api";
import { i18nTranslate, Enterprise } from "@ctra/i18n";
import { KPIThresholdDownV2, KPIThresholdUpV2 } from "@ctra/components";
import { Units, UnitSystem, memoize, Optional } from "@ctra/utils";

import { RegularInsightValidation, KPIInsightBody } from "@insights";
import { loadUnitSystem } from "@base";

import { GenericInsightBase } from "./Base";

/**
 * KPI insight
 */
class KPIInsight extends GenericInsightBase {
  /**
   * Format the ugly metadata to something more readable
   */
  @memoize
  getMetadata(): {
    dataDescriptor: {
      id: DataDescriptorEntity["id"];
      typeName: DataDescriptorEntity["dataProperties"]["typeName"];
    };
    changeDirection: "increase" | "decrease";
    trigger: {
      value: number;
      datetime: string;
    };
    thresholds: {
      min: number;
      max: number;
    };
    unitSystem: UnitSystem;
    group: Optional<string>;
  } {
    const {
      userPreferences: {
        unitSystem,
        algorithmOptions: { minThreshold, maxThreshold },
        group
      },
      dataDescription: { dataTypeName, dataDescriptorId, insightDirection },
      triggerPoint: {
        date,
        context: { value }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } = GenericInsightBase.prototype.getMetadata.apply(this) as Record<string, any>;

    return {
      dataDescriptor: {
        id: dataDescriptorId,
        typeName: dataTypeName
      },
      changeDirection: insightDirection,
      trigger: {
        value,
        datetime: date
      },
      thresholds: {
        min: minThreshold,
        max: maxThreshold
      },
      group,
      /**
       * convert into internal format i.e lower case
       */
      unitSystem: unitSystem?.toLowerCase() || UnitSystem.metric
    };
  }

  /**
   * Get parameters for translating the title and the description
   * @returns {{changeDirection: "increase" | "decrease", datetime: string, thresholdMin: string, kpi: string, thresholdMax: string, value: string, group: string | undefined}}
   */
  @memoize
  getTranslationParams() {
    const {
      kpi,
      unitSystem: { unit: tUnit }
    } = Enterprise;

    const {
      dataDescriptor: { id, typeName },
      trigger: { value, datetime },
      thresholds: { min, max },
      changeDirection,
      unitSystem: kpiUnitSystem
    } = this.getMetadata();

    const units = App.entities.getDataDescriptor(App.store.getState(), { id })?.valueProperties?.units;
    const globalUnitSystem = loadUnitSystem();
    const unit = _.get(units, globalUnitSystem, _.get(units, UnitSystem.metric));

    const kpiName = i18nTranslate(kpi.displayName(typeName), {
      variant: "short",
      case: null,
      makeDefaultValue: true
    });

    const convert = (value: number) =>
      Units.convertToUnitSystem(value, units, { from: kpiUnitSystem, to: globalUnitSystem });

    return {
      changeDirection,
      kpi: kpiName,
      value: Units.format(convert(value), unit, i18nTranslate(tUnit(unit)), { precision: 1 }),
      datetime: moment(datetime).format("LL"),
      thresholdMin: Units.format(convert(min), unit, i18nTranslate(tUnit(unit)), { precision: 1 }),
      thresholdMax: Units.format(convert(max), unit, i18nTranslate(tUnit(unit)), { precision: 1 }),
      group: this.getTargetGroupName({ withPrefix: false })
    };
  }

  /**
   * Get the herd group or pen name
   * @return {string}
   */
  @memoize
  getTargetGroupName({ withPrefix } = { withPrefix: true }): Optional<string> {
    const {
      settings: {
        insights: {
          kpiInsightForm: {
            labels: { pen, herdGroup }
          }
        }
      }
    } = Enterprise;

    const { farm } = this.entity;

    const {
      dataDescriptor: { id: dataDescriptorID },
      group
    } = this.getMetadata();

    if (!group) {
      return void 0;
    }

    const dataDictionary = App.entities.getDataDictionary(App.store.getState());
    const charts = App.entities.getChartList(App.store.getState());
    const herdGroups = App.entities.getHerdGroups(App.store.getState(), { farmID: farm });
    const pens = _.keyBy(App.entities.getPens(App.store.getState(), { farmID: farm }), "name");

    const chartID = _.get(dataDictionary, [dataDescriptorID, "supportedCharts", 0]);
    const seriesType = _.get(charts, [chartID, "dataProperties", "seriesType"]);
    const groupName = _.get({ ...herdGroups, ...pens }, [group, "name"]);

    return _.compact([
      withPrefix ? i18nTranslate(seriesType === ChartSeriesType.herdGroup ? herdGroup : pen) : null,
      _.defaultTo(groupName, group)
    ]).join(" ");
  }

  /**
   * Replace the icons based on the metadata and add it's own validation
   */
  @memoize
  getComponents(): ReturnType<typeof GenericInsightBase.prototype.getComponents> {
    const { changeDirection } = this.getMetadata();

    return {
      Icon: changeDirection === "increase" ? KPIThresholdUpV2 : KPIThresholdDownV2,
      ListIcon: changeDirection === "increase" ? KPIThresholdUpV2 : KPIThresholdDownV2,
      Validation: RegularInsightValidation,
      Body: KPIInsightBody
    };
  }
}

export { KPIInsight };
