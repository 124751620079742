import { AsyncType, createAsyncTypes, HOUR } from "@ctra/utils";

const ns = "app/chart";

/**
 * Fetch an iframe URL for the given chart ID and filters
 * @category Type
 */
const FETCH_CHART_DATA: AsyncType = createAsyncTypes(ns, "FETCH_CHART_DATA", {
  primary: "hash", // hash generated from the chart id and filters
  expires: HOUR / 2,
  replays: true
});

/**
 * Share a chart publicly
 * @type {AsyncType}
 */
const SHARE_CHART: AsyncType = createAsyncTypes(ns, "SHARE_CHART", {
  preservesPayload: true,
  primary: "hash"
});

/**
 * Update a shared chart
 * @type {AsyncType}
 */
const UPDATE_SHARED_CHART: AsyncType = createAsyncTypes(ns, "UPDATE_SHARED_CHART", {
  preservesPayload: true,
  primary: "hash"
});

/**
 * Load a shared chart
 * @type {AsyncType}
 */
const FETCH_SHARED_CHART: AsyncType = createAsyncTypes(ns, "FETCH_SHARED_CHART", {
  preservesPayload: true
});

/**
 * Fetch attachment from a shared chart
 * @category Type
 */
const FETCH_SNAPSHOT_DATA: AsyncType = createAsyncTypes(ns, "FETCH_SNAPSHOT_DATA", {
  preservesPayload: true
});

/**
 * Cancel fetching chart data
 * @type {string}
 */
const CANCEL_CHART_DATA_FETCH = `${ns}/CANCEL_CHART_DATA_FETCH`;

/**
 * Fetch farm correlations
 * @category Type
 */
const FETCH_FARM_CORRELATIONS: AsyncType = createAsyncTypes(ns, "FETCH_FARM_CORRELATIONS", {
  primary: "farmID"
});

/**
 * Fetch chart correlations
 * @category Type
 */
const FETCH_CHART_CORRELATIONS: AsyncType = createAsyncTypes(ns, "FETCH_CHART_CORRELATIONS", {
  primary: "farmID"
});

export default {
  FETCH_CHART_DATA,
  FETCH_FARM_CORRELATIONS,
  FETCH_CHART_CORRELATIONS,
  SHARE_CHART,
  UPDATE_SHARED_CHART,
  FETCH_SHARED_CHART,
  FETCH_SNAPSHOT_DATA,
  CANCEL_CHART_DATA_FETCH
};
