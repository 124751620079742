import { FC } from "react";
import * as _ from "lodash";
import { useLocation, useParams } from "react-router-dom";

import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { Charts, Analytics, Tag, Col } from "@ctra/components";

import { useFarm } from "@farms";
import { useDataDictionary } from "@base";
import { Chart, ChartAPIContext, ChartFilterContext } from "@chart";
import { Enterprise } from "@ctra/api";
import { CompiledRoutes } from "@routes";

import styles from "./MiniChart.module.less";

const {
  V3: { MiniChartCard }
} = Analytics;

interface MiniChartProps {
  metric: string;
  variant: string;
  source?: string;
}

/**
 * List of chart cards
 * @returns
 */
export const MiniChart: FC<MiniChartProps> = ({ metric, variant, source }) => {
  const { t } = useTranslation();
  const { farm } = useFarm();
  const { dashboard } = useParams<{ dashboard: string }>();
  const { search, state } = useLocation();
  const searchParams = new URLSearchParams(search);

  const {
    api: { extractChartInfo },
    groups: { projectionMetrics },
    dataDescriptors
  } = useDataDictionary();

  /**
   * Default interval for the chart
   */
  const defaultInterval = _.get(dataDescriptors, [variant, "valueProperties", "defaultInterval"], "P3M");

  const {
    analytics: {
      config: {
        metric: { displayName },
        variant: variantCopy,
        source: sourceCopy,
        projections
      }
    },
    chart: { description }
  } = Content;

  const chartInfo = extractChartInfo({ source, variant, metric });

  return (
    <ChartFilterContext.Provider isoDuration={defaultInterval}>
      <ChartAPIContext.Provider dataDescriptorID={variant} farmID={farm?.id}>
        <ChartAPIContext.Consumer>
          {({ chart: { title } }) => (
            <MiniChartCard
              metric={t(displayName(chartInfo.nameToDisplay), { makeDefaultValue: true })}
              source={chartInfo.sourceName && t(sourceCopy(chartInfo.sourceName))}
              variant={t(variantCopy(chartInfo.variantType))}
              tooltip={t(description(title))}
              onClick={() => {
                source && searchParams.set("source", source);
                searchParams.set("variant", variant);

                Enterprise.history.push({
                  pathname: CompiledRoutes.app.analytics.dashboard.addChart.metric({
                    dashboard,
                    metricID: metric
                  }),
                  search: searchParams.toString(),
                  state
                });
              }}
            >
              <Chart
                variant={Charts.ChartVariant.Mini}
                config={{ legend: false, height: 195, tooltip: false }}
                viewOptions={{ showAnnotations: false, showAnomalies: false }}
              />
              <Col span={24} className={styles.Col}>
                {_.includes(projectionMetrics, metric) && <Tag className={styles.Tag}>{t(projections)}</Tag>}
              </Col>
            </MiniChartCard>
          )}
        </ChartAPIContext.Consumer>
      </ChartAPIContext.Provider>
    </ChartFilterContext.Provider>
  );
};
