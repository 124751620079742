import { FC } from "react";
import * as _ from "lodash";
import Markdown from "react-markdown";

import { Analytics, DndProvider, HTML5Backend, CtraLayout, Grid, Skeleton, Button } from "@ctra/components";

import { Enterprise, EventSourceType } from "@ctra/api";
import { classname } from "@ctra/utils";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";

import { Routes } from "@routes";
import { useCurrentUser } from "@auth";

import { ChartAPIContext, ChartFilterContext, usePublicChart } from "../../providers";
import { Chart } from "../Chart";
import styles from "./PublicChart.module.less";

const { WidgetWrapper, ContentWrapper } = CtraLayout;

const {
  V3: { ChartCard }
} = Analytics;

const {
  analytics: {
    share: { publicPage }
  }
} = Content;

export const PublicChart: FC = () => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const { lg } = Grid.useBreakpoint();

  const {
    meta: { isFetching },
    api: { isError },
    chart
  } = usePublicChart();

  const metadata = _.merge(
    {
      locale: "en-US",
      farmName: null
    },
    _.isError(chart) ? {} : _.get(chart, ["context", "metadata"])
  );

  const metric = metadata.metric ? t<string>(metadata.metric, { locale: metadata.locale }) : "";
  const variant = metadata.variant ? t<string>(metadata.variant, { locale: metadata.locale }) : "";
  const source = metadata.source ? t<string>(metadata.source, { locale: metadata.locale }) : "";
  const translationParams = { ...metadata, metric, variant, source };

  return (
    <section style={user.id ? void 0 : { width: lg ? "50%" : "100%", margin: "0 auto" }}>
      <Skeleton loading={isFetching}>
        <WidgetWrapper title={t<string>(publicPage.title, translationParams)}>
          {!isError(chart) && _.get(chart, ["text"]) && (
            <ContentWrapper isLoading={isFetching} className={classname(styles.Widget)}>
              <Markdown>
                {t<string>(publicPage.description, {
                  ...translationParams,
                  message: chart!.text
                })}
              </Markdown>
            </ContentWrapper>
          )}
          {!user.id && (
            <ContentWrapper
              accent="teal"
              className={classname(styles.Widget)}
              title={t<string>(publicPage.cta.title, translationParams)}
              isLoading={isFetching}
            >
              <Markdown>{t<string>(publicPage.cta.text, translationParams)}</Markdown>
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  Enterprise.history.push(Routes.auth.websiteSignup);
                }}
              >
                {t<string>(publicPage.cta.button)}
              </Button>
            </ContentWrapper>
          )}
          <DndProvider backend={HTML5Backend}>
            <ChartCard isLoading={isFetching} index={0} metric={metric} variant={variant} source={source}>
              {chart && !isError(chart) && chart.context ? (
                <ChartFilterContext.Provider
                  isoDuration={null}
                  series={_.get(metadata, "series.active", void 0)}
                >
                  <ChartAPIContext.Provider
                    dataDescriptorID={chart.context.sourceId}
                    farmID={chart.context.farmId}
                  >
                    <Chart
                      viewOptions={{
                        skipFarmSupportCheck: true,
                        showToolbar: false,
                        zoomed: false,
                        showEvents: {
                          [EventSourceType.aiModel]: false,
                          [EventSourceType.customEvent]: false,
                          [EventSourceType.genericLabel]: false,
                          [EventSourceType.genericInsight]: false,
                          [EventSourceType.idaQnA]: false,
                          [EventSourceType.gamePlanTask]: false
                        },
                        showEventToggle: false
                      }}
                    />
                  </ChartAPIContext.Provider>
                </ChartFilterContext.Provider>
              ) : null}
            </ChartCard>
          </DndProvider>
        </WidgetWrapper>
      </Skeleton>
    </section>
  );
};
